const Rounds:roundI[] = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((round) => ({
  id: round,
  prediction: undefined,
  result: undefined,
  score: undefined,
  turn: false,
  active: false,
  start: false
}))

class player implements playerI {
  
    name = ""
    rounds:roundI[] = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((round) => ({
      id: round,
      prediction: undefined,
      result: undefined,
      score: undefined,
      turn: false,
      active: false,
      start: false
    }))

  constructor (name: string) {
    this.name = name
  }

  setRound() {
    this.rounds[1].score = 99
  }

}

interface playerI {
  name: string;
  rounds: roundI[];
}

interface roundI {
  id:number;
  prediction?: number | undefined;
  result?: number | undefined;
  score?: number | undefined;
  turn: boolean;
  active: boolean;
  start: boolean;
}

export {  player, Rounds, roundI };
