import { defineStore } from "pinia";
import { player, Rounds, roundI } from "@/interfaces/player";
import { computed, ref } from "vue";

export const useStore = defineStore("game", () => {
  const players = ref([] as player[])
  const rounds = ref(Rounds);

  players.value = getFromLocalStorage()

  if(players.value) {
    setActiveRound()
  }

  function getFromLocalStorage () {
    console.log('get from localstorage')
    const game = window.localStorage.getItem('elferaus_game')
    if(game) {
      return JSON.parse(game) as unknown as player[]
    } else {
      return [] as unknown as player[]
    }
  }

  function saveInLocaleStorage () {
    console.log('save to localstorage')
    window.localStorage.setItem('elferaus_game', JSON.stringify(players.value))
  }

  function addPlayer(username: string) {
    if (username) {
      const user = new player(username);
      players.value.push(user);
      setTurns();
      saveInLocaleStorage()
      setActiveRound();
    }
  }

  function setTurns() {
    if (amountOfPlayers.value > 1) {
      players.value = players.value.map((player, index) => {
        let turn = index;
        player.rounds.map((round, index) => {
          if (index == turn) {
            round.turn = true;
            turn = turn + amountOfPlayers.value;
          } else {
            round.turn = false;
          }

          if (index == (turn + 1) ) {
            round.start = true; 
          } else {
            round.start = false;
          }

          return round;
        });
        return player;
      });
    }
  }

  function setActiveRound() {
    debugger
    rounds.value.forEach((round, index) => {
      let active = false;
      players.value.forEach((player) => {
        if (player.rounds[index].score == undefined) {
          active = true;
        }
      });
      round.active = active;
    });
  }

  function reset() {
    players.value = [];
    rounds.value = Rounds || [];
  }

  const amountOfCards = computed(() => {
    return 10;
  });

  const amountOfPlayers = computed(() => {
    return players.value.length;
  });

  const sumOfpredictionInRound = computed((roundIndex: number) => {
    return (roundIndex: number) =>
      players.value.reduce((prev: number, player) => {
        const prediction = player.rounds[roundIndex].prediction || 0;

        if (prediction) {
          prev = prev + prediction;
          return prev;
        } else {
          return prev;
        }
      }, 0);
  });

  const firstActiveRound = computed(() => {
    return rounds.value.findIndex((round) => round.active) == -1
      ? 0
      : rounds.value.findIndex((round) => round.active);
  });

  /* playerRound updates */

  function updatePrediction(roundId: number, playerId: number, event: any) {
    const playerT = players.value[playerId]
    const round = playerT.rounds[roundId]
    
    const prediction = isNaN(parseFloat(event.target.value))
      ? 0
      : parseFloat(event.target.value);

    let score = undefined
    
    if (round.score != undefined && round.prediction != undefined) {
      score = prediction == round.result ? 10 + prediction : round.result
    }

    playerT.rounds[roundId].prediction = prediction
    playerT.rounds[roundId].score = score
    
    setActiveRound();
    saveInLocaleStorage()
  }

  function updateResult (roundId: number, playerId: number, event: any) {
    const playerT = players.value[playerId]
    const round = playerT.rounds[roundId]
    
    const prediction = round.prediction || 0

    const result = isNaN(parseFloat(event.target.value))
    ? undefined
    : parseFloat(event.target.value);

    let score = undefined as any

    if (result != undefined && round.prediction != undefined) {
      score = prediction == result ? 10 + prediction : result
    }

    players.value[playerId].rounds[roundId].result = result
    players.value[playerId].rounds[roundId].score = score

    setActiveRound();
    saveInLocaleStorage()
  }

  return {
    players,
    rounds,
    addPlayer,
    amountOfCards,
    amountOfPlayers,
    sumOfpredictionInRound,
    firstActiveRound,
    reset,
    setActiveRound,
    setTurns,
    updatePrediction,
    updateResult
  };
});
