import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import './styles/app.css';
import 'flowbite';

const pinia = createPinia()
const app = createApp(App)

app.use(pinia)
app.mount('#app')
